import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { VideoElement2 } from "../../../components/video";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const section_1 = require("../../../assets/img/blogs/ai_it_hr_blog_1.png");
const section_2 = require("../../../assets/img/blogs/ai_it_hr_blog_2.png");
const section_3 = require("../../../assets/img/blogs/ai_it_hr_blog_3.png");
const section_4 = require("../../../assets/img/blogs/ai_it_hr_blog_4.png");
const section_5 = require("../../../assets/img/blogs/ai_it_hr_blog_5.png");
const section_6 = require("../../../assets/img/blogs/ai_it_hr_blog_6.png");
const section_7 = require("../../../assets/img/blogs/ai_it_hr_blog_7.png");
const section_8 = require("../../../assets/img/blogs/ai_it_hr_blog_8.png");
const section_9 = require("../../../assets/img/blogs/ai_it_hr_blog_4.png");
const section_10 = require("../../../assets/img/blogs/ai_it_hr_blog_5.png");
const section_11 = require("../../../assets/img/blogs/ai_it_hr_blog_6.png");
const section_12 = require("../../../assets/img/blogs/ai_it_hr_blog_7.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="10 Ways to Use AI Chatbots for Internal IT and HR Support"
        description="Discover 10 innovative ways to leverage AI chatbots for enhancing internal IT and HR support with Workativ. Streamline processes, boost efficiency, and improve employee satisfaction."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={section_1}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header">
                            10 Ways to Use AI Chatbots for Internal IT and HR
                            Support – Workativ
                          </h1>
                        </div>
                      </div>

                      <div className="market_wrapper_page">
                        <p class="font-section-normal-text mt-4 line-height-2">
                          Are your HR and IT processes creating frustrations and
                          stress for your employees?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Have you ever realized why your people struggle so
                          much to get help for common HR or IT issues?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The standard picture across every industry is that
                          employees spend much time finding critical yet simple
                          information about HR and IT services.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If you can fix your existing employee experience
                          hurdles, you can improve the employee experience.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                         <a href="https://workativ.com/conversational-ai-platform"> Conversational AI Chatbots that work within your
                          business communication channels, such as Microsoft
                          Teams or Slack,</a> can improve employee experience in
                          many ways. From HR activities to IT support and
                          training, an AI-driven chatbot for internal employees
                          can make life easier for your employees and provide
                          greater efficiencies for your business.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          In this article, we explore ten ways{" "}
                          <a href="https://workativ.com/conversational-ai-platform">
                            conversational AI chatbots
                          </a>{" "}
                          can improve the employee experience by automating
                          internal IT and HR support processes.
                        </p>
                      </div>

                      <NocodeWrapper />
                      {/* 
                      {isSmall ? null : <OnScrollPopup />} */}

                      <div className=" market_wrapper_page">
                        <h2 className="font-section-sub-header-small-bold">
                          What is an internal conversational AI chatbot?
                        </h2>
                        <img
                          src={section_1}
                          alt="AI chatbots for Internal IT and HR support"
                          className="mb-3"
                        />
                        <p class="font-section-normal-text line-height-2">
                          An internal conversational AI chatbot is a platform
                          that helps businesses provide their employees with
                          real-time responses to resolve problems and perform
                          tasks. For example, businesses can use AI chatbots for
                          Internal IT and HR support within their business
                          communication channels, such as Slack or MS Teams, to
                          boost employee productivity and operational
                          efficiency.
                        </p>
                        <h2 className="font-section-sub-header-small-bold">
                          How does a conversational AI chatbot work?
                        </h2>
                        <img
                          src={section_2}
                          alt="AI chatbots for Internal IT and HR support"
                          className="mb-3"
                        />
                        <p class="font-section-normal-text line-height-2">
                          An internal conversational AI chatbot uses natural
                          language processing (NLP) and natural language
                          understanding (NLU) to derive context from human
                          conversations. With the capability to recognize the
                          intent behind a question or request (for example,
                          password reset, login issues, or tax calculations), an
                          AI internal chatbot can easily simulate employee
                          requests and provide an intelligent response to help
                          execute an action.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With more sophisticated AI technology, such as
                          Generative AI, HR and IT support bots can work faster
                          to automate responses and resolve problems.
                        </p>
                        <h2 className="font-section-sub-header-small-bold">
                          The top five ways AI chatbots help with internal IT
                          support
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          IT Service Management (ITSM) can now be automated
                          through AI chatbots for IT support. Infused with
                          natural language understanding or NLU, AI chatbots for
                          IT support empower your workforce with better
                          productivity and compliance that benefits the help
                          desk overall. Let’s take a look at five ways in which
                          you can make use of AI chatbots for IT support.
                        </p>
                        <h3 className="font-section-sub-header-small-home  ">
                          #1 IT support— password reset
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          Internal IT support follows the 80/20 rule to a
                          certain extent. Regarding support, high-frequency,
                          common questions represent about 80% of the total
                          support volume. The other 20% are usually outlier
                          questions or issues. High-frequency questions are low
                          in value and simple to solve without human
                          intervention. Examples include password resets or
                          account unlocks, which an AI chatbot can solve
                          instantly.
                        </p>
                        <img
                          src={section_3}
                          alt="Internal chatbot builder to create a workflow for ‘reset password’"
                          className="mb-3"
                        />

                        <p class="font-section-normal-text line-height-2">
                          Create workflows for your bot and integrate them with
                          your business communication channel
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Build appropriate dialog for ‘reset password’ using a
                          chatbot builder and dialog management module
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          When your employee wants to ask a question about
                          ‘reset a password,’ the bot can explain the steps and
                          help reset the password without the need to engage
                          agents and prolong the wait times
                        </p>

                        <h3 className="font-section-sub-header-small-home  ">
                          #2 IT support— instant account unlock
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          Low-value, repetitive questions of employees, such as
                          “unlock account,” can typically be solved with an AI
                          chatbot using out-of-the-box IT process automation
                          since the solution is the same for every employee.
                        </p>
                        <img
                          src={section_4}
                          alt="Internal chatbot to automate ‘unlock account’ processes"
                          className="mb-3"
                        />

                        <p class="font-section-normal-text line-height-2">
                          Your{" "}
                          <a href="https://workativ.com/conversational-ai-platform/features">
                            internal AI chatbot
                          </a>{" "}
                          can use keywords and understand the syntax to unlock
                          employees' accounts quickly via OTP verification.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          This gives employees the satisfaction of solving their
                          issues rather than submitting a ticket and waiting for
                          help.
                        </p>

                        <h3 className="font-section-sub-header-small-home">
                          #3 IT support—better identity and access management
                          (IAM)
                        </h3>
                        <img
                          src={section_5}
                          alt="Internal chatbot to automate ‘unlock account’ processes"
                          className="mb-3"
                        />
                        <p class="font-section-normal-text line-height-2">
                          An AI chatbot with a self-service interface can
                          seamlessly maintain the compliance process for
                          identity access management platforms. As a result, you
                          can handle the complexity of multiple access
                          providers, including third-party access provider
                          integrations, and great reporting tools, and leverage
                          outstanding UI to provide a great user experience,
                          everything conversationally.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The secure maintenance of the IAM tool enables you to
                          manage many tasks through–
                        </p>
                        <ul className="float-left w-100 blogs_ul_upd list-style-type-none">
                          <li class="font-section-normal-text-testimonials line-height-18">
                            - Access requests
                          </li>
                          <li class="font-section-normal-text-testimonials line-height-18">
                            - Profile information update
                          </li>
                          <li class="font-section-normal-text-testimonials line-height-18">
                            - MFA requests etc
                          </li>
                        </ul>
                        <ExistingBlogCta
                          ContentCta="Auto-resolve 60% of Employee IT & HR Queries with AI Chatbot."
                          ButtonText="Book a Demo"
                          isColor="white"
                          backgroundImage={cta_2}
                          mobileBackgroundImage={cta_2_mob}
                        />
                      </div>

                      <div className=" market_wrapper_page">
                        <h2 className="font-section-sub-header-small-bold">
                          Auto-resolve 60% of Employee IT & HR Queries with AI
                          Chatbot.
                        </h2>

                        <h3 className="font-section-sub-header-small-home">
                          #4 IT support—user provisioning and deprovisioning
                        </h3>
                        <img
                          src={section_6}
                          alt="Internal chatbot to automate ‘unlock account’ processes"
                          className="mb-3"
                        />
    <p class="font-section-normal-text line-height-2">
    User provisioning and de-provisioning are often one of the first tasks an IT team looks to automate. That’s because it’s a significant burden to control, with different processes in place for a new hire versus a staff change or departure. User management tasks also involve various systems, a lot of room for error — such as misspelling somebody’s name — and demand a thorough understanding of a user’s job requirements and the systems they’ll need to access.

                        </p>
                        <p class="font-section-normal-text line-height-2">
                        An AI chatbot for IT support empowers IT to help desk agents provision/de-provision users conversationally. The chatbot also applies proactive policy controls throughout the request and provisioning processes.

                        </p>
                        <h3 className="font-section-sub-header-small-home">
                          #5 IT support—secure VPN settings
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          For the organization-wide security of computer
                          systems, setting up a VPN is necessary for your hybrid
                          workforce.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          When a VPN is set up, there are too many critical
                          processes to follow for a smooth operation. Employees
                          may need help configuring VPN settings, connecting to
                          the VPN, etc.
                        </p>
                        <img
                          src={section_7}
                          alt="leave management automation through internal chatbot for IT and HR support"
                          className="mb-3"
                        />
                        <p class="font-section-normal-text line-height-2">
                          AI chatbots that integrate automated workflows with
                          probable VPN scenarios can help employees fix
                          VPN-related problems and continue to work without
                          prolonged downtime.
                        </p>
                      </div>
                      <div className=" market_wrapper_page">
                        <h2 className="font-section-sub-header-small-bold">
                          The top five ways AI chatbots help with internal HR
                          support
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          Human Resources is the primary department of any
                          organization, as it maintains all employees’ leaves,
                          reimbursements, salaries, and more. The HR
                          administration is constantly overwhelmed with hiring
                          employees, correcting their complaints, conducting
                          studies, accepting feedback, etc. There is a vast
                          demand for advancement to streamline workflows.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          An AI internal chatbot for HR support can drive
                          significant performance in the human resources
                          department by supporting the HR team in various ways.
                          Developing HR chatbots can solve many problems in the
                          HR department, as they help enhance and improve
                          employee productivity. Here are five areas in which HR
                          chatbots can help.
                        </p>
                        <h3 className="font-section-sub-header-small-home">
                          #1 HR support—better workflows for paid time offs
                          (PTOs)/leave management
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          Internal{" "}
                          <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                            HR chatbots
                          </a>{" "}
                          let people use them to interact with their existing
                          talent management and HR software. For example,
                          instead of logging onto their company’s
                          time-and-attendance platform to request time off, an
                          employee could type a text in natural human language
                          into Slack or Teams, such as “I’d like to request time
                          off” or “PTO.” It would trigger the HR chatbot to ask
                          questions about the type of time off before processing
                          the request in real time and recording it on the
                          attendance app for their company.
                        </p>
                        <img
                          src={section_8}
                          alt="leave management automation through internal chatbot for IT and HR support"
                          className="mb-3"
                        />
                        <p class="font-section-normal-text line-height-2">
                          Create your internal{" "}
                          <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                            HR chatbot
                          </a>
                        </p>
                        <h3 className="font-section-sub-header-small-home">
                          #2 HR support—easier expense management
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          Do you need to automate time-consuming expense
                          management tasks? Connect AI chatbots with expense
                          management tools like Expensify to create the desired
                          workflows and provide end-to-end expense management
                          self-service to employees.
                        </p>
                        <img
                          src={section_9}
                          alt="leave management automation through internal chatbot for IT and HR support"
                          className="mb-3"
                        />
                        <p class="font-section-normal-text line-height-2">
                          An employee types in a specific phrase for expense
                          claim or reimbursement.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The internal HR bot can escalate a couple of options,
                          such as travel, internet, meals, etc
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          An employee can choose a suitable response and further
                          provide appropriate information for a claim
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          An employee further uploads the receipt for the
                          manager’s approval
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The HR bot can trigger a notification to the manager
                          and also send the status to the expense management
                          tool
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The employee can claim expenses only if the manager
                          approves it and gets notified via the chatbot message
                        </p>
                        <h3 className="font-section-sub-header-small-home">
                          #3 HR support—effortless employee onboarding
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          When a new employee joins the company, help desk
                          agents or HR personnel have to use an HRMS tool to
                          manually assign an email account, username, and home
                          directory and grant access permissions to them
                          depending on their role and entitlement rules. The
                          process is time-consuming and drains energy.
                          <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                            {" "}
                            Employee onboarding automation
                          </a>{" "}
                          alleviates HR process complexity and offers
                          efficiency.
                        </p>
                        <img
                          src={section_10}
                          alt="leave management automation through internal chatbot for IT and HR support"
                          className="mb-3"
                        />
                        <p class="font-section-normal-text line-height-2">
                          Connect your internal HR bot with HRSM tools,
                          including BambooHR, ZohoPeople, and SAP
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Trigger workflows as soon as an employee is added to
                          the HRSM application
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Automatically add an employee to IAM applications
                          tools such as Azure AD, Okta
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Add an employee to ITSM applications, including
                          Freshdesk, ServiceNow, etc
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Provision licenses to apps such as Slack, Asana,
                          Outlook, Gmail, etc
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Get a message in the HR bot channel once the new hire
                          is onboarded successfully
                        </p>
                        <h3 className="font-section-sub-header-small-home">
                          #4 HR support—employee offboarding
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          When an employee leaves an organization, help desk
                          agents must revoke their accounts and access rights
                          for security purposes. Help desk agents or HRs should
                          disable the former employee’s account using the HRMS
                          tool manually and delete it permanently after a few
                          days. By introducing an{" "}
                          <a href="https://workativ.com/use-cases/employee-offboarding-automation">
                            AI-powered HR chatbot with HR process automation
                          </a>{" "}
                          into this equation, HRs can effortlessly offboard an
                          employee with minimal effort just by having a quick
                          chat with the HR chatbot.
                        </p>
                        <img
                          src={section_11}
                          alt="leave management automation through internal chatbot for IT and HR support"
                          className="mb-3"
                        />

                        <p class="font-section-normal-text line-height-2">
                          Create an HR bot for offboarding employees
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Integrate this with your Slack or Teams
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Deactivate an employee in the HRSM tool
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          IAM tools and ITSM tools will pull up employee
                          deactivation information
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          License to emails or other apps will be deactivated
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          A message will trigger to notify the HR and IT team
                          via email
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          A ticket will escalate to ensure employee offboarding{" "}
                        </p>

                        <h3 className="font-section-sub-header-small-home">
                          #5 HR support—salary information at the fingertips
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          AI chatbots for HR support eliminate the need for
                          employees to contact HR for simple payroll issues or
                          questions.{" "}
                          <a href="https://workativ.com/use-cases/payroll-enquiry-automation">
                            HR chatbots can address multiple payroll FAQs or
                            payroll-related requests
                          </a>{" "}
                          from employees in the blink of an eye.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The HR chatbot can handle inquiries about salary
                          updates, tax deductions, bonuses, and incentives,
                          giving HR teams more time to focus on enabling growth
                          and employee welfare.
                        </p>
                        <img
                          src={section_12}
                          alt="payroll management automation within internal chatbot for HR support "
                          className="mb-3"
                        />

                        <p class="font-section-normal-text line-height-2">
                          Integrate a chatbot workflow with your business
                          communication channel{" "}
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Pull up information from HRSM tools, including
                          BambooHR, etc
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Trigger a salary inquiry event to send an email and
                          then create a ticket{" "}
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Provide payroll details for the employees from HRSM
                          tools
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Send a resolved ticket for payroll inquiry
                        </p>
                      </div>

                      <div className="market_wrapper_page">
                        <h2 className="font-section-sub-header-bold">
                          Conclusion
                        </h2>
                        <p className="font-section-normal-text line-height-2">
                          If you’re thinking of using an AI chatbot, make sure
                          first to identify your company’s problem area. Are you
                          inundated with requests and inquiries? Could your
                          agents be spending their time more efficiently? It’s
                          equally as important to understand the scope of the
                          chatbot you might be implementing. Does it help with
                          repetitive tasks? Or is it an expert on a specific
                          product? A value-adding bot can collect employee
                          information to arm agents with the info they need to
                          help an employee or solve complex problems.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform">
                            {" "}
                            Workativ’s no-code Conversational AI + workplace
                            Automation platform{" "}
                          </a>
                          allows you to integrate with your ITSM and HRMS tools
                          and create the desired workflows to give your
                          employees the IT/HR support they need instantly.{" "}
                          <a href="https://workativ.com/conversational-ai-platform">
                            Try Workativ today by signing up for a FREE now
                          </a>
                        </p>
                      </div>
                      <div className="market_wrapper_page">
                        <h2 className="font-section-sub-header-bold">FAQs</h2>
                        <h3 class="font-section-sub-header-small color-black">
                          1. How does the use of AI chatbots for internal IT and
                          HR support impact overall employee experience, and
                          what specific metrics can be used to measure this
                          impact?
                        </h3>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          The article discusses the benefits of using AI
                          chatbots for internal IT and HR support in improving
                          employee experience but does not delve into specific
                          metrics or KPIs that can quantify this improvement.
                          Understanding how the implementation of chatbots
                          affects employee satisfaction, productivity, and
                          engagement could provide valuable insights for
                          organizations looking to assess the effectiveness of
                          such initiatives.
                        </p>
                        <h3 class="font-section-sub-header-small color-black">
                          2. What are some potential challenges or drawbacks
                          associated with implementing AI chatbots for internal
                          IT and HR support, and how can organizations overcome
                          these obstacles?
                        </h3>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          While the article highlights the advantages of using
                          AI chatbots for IT and HR support, it does not address
                          any potential challenges or limitations that
                          organizations may encounter during implementation.
                          Exploring issues such as data privacy concerns, user
                          acceptance, or technical limitations could offer
                          readers a more comprehensive understanding of the
                          complexities involved in deploying chatbot solutions
                          within organizational contexts.
                        </p>
                        <h3 class="font-section-sub-header-small color-black">
                          3. Are there any best practices or guidelines for
                          designing and implementing AI chatbots for internal IT
                          and HR support, particularly regarding user interface
                          design, integration with existing systems, or training
                          for end-users?
                        </h3>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          The article provides an overview of various use cases
                          for AI chatbots in internal IT and HR support but does
                          not offer specific guidance on how organizations can
                          effectively design and implement these chatbots.
                          Providing insights into best practices for chatbot
                          design, integration strategies with existing IT and HR
                          systems, or training approaches for end-users could
                          help readers navigate the process of deploying chatbot
                          solutions more effectively.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-vs-conversational-ai">
                                Chatbots vs Conversational AI
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                {" "}
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href={"https://assistant.workativ.com/authentication/u/direct-signup"}
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
